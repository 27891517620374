import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Router, Route, Switch } from "react-router-dom";
import App from "App.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter history={hist}>
    <Switch>
      <Route path="/" name="Home" component={App} />
    </Switch>
  </BrowserRouter >,
  document.getElementById("root")
);
