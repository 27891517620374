// Imports
import React, { Component } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import { Redirect } from 'react-router';

import locationSearchBarStyle from "assets/jss/material-kit-react/components/locationSearchBarStyle.jsx";

// Import Search Bar Components

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';
import MyLocationIcon from '@material-ui/icons/MyLocation';



// Import React Scrit Libraray to load Google object
import Script from 'react-load-script';

class LocationSearchBar extends Component {
  // Define Constructor
  constructor(props) {
    super(props);

    // Declare State
    this.state = {
      city: '',
      query: ''
    };

    // Bind Functions
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleOnSearchClick = this.handleOnSearchClick.bind(this)

  }

  handleScriptLoad() {
    // Declare Options For Autocomplete
    var options = {
      types: ['(cities)'],
    };

    // Initialize Google Autocomplete
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      options,
    );

    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed', this.handlePlaceSelect);
  }

  handlePlaceSelect() {

    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;

    // Check if address is valid
    if (address) {
      // Set State
      this.setState(
        {
          city: address[0].long_name,
          query: addressObject.formatted_address,
        }
      );
    }
  }
  handleOnSearchClick() {
    this.setState({ redirect: true });
  }
  render() {

    const { classes, ...rest } = this.props;
    if (this.state.redirect) {
      return <Redirect push to={"/search?city=" + this.state.city + "&address=" + this.state.query} />;
    }
    return (
      <div>
        <Script
          url="https://maps.googleapis.com/maps/api/js?key=AIzaSyDXngidTgGw7yjL0qnzbM_JwzCvTohRwQk&libraries=places"
          onLoad={this.handleScriptLoad}
        />

        <Paper className={classes.root} elevation={1}>
          <InputBase id="autocomplete" className={classes.input} placeholder="Search: City, Street or Postcode" />
          <IconButton onClick={this.handleOnSearchClick} className={classes.iconButton} aria-label="Search">
            <SearchIcon />
          </IconButton>
          <Divider className={classes.divider} />
          <IconButton color="primary" className={classes.iconButton} aria-label="Near me now">
            <MyLocationIcon />
          </IconButton>
        </Paper>

      </div>
    );
  }
}

export default withStyles(locationSearchBarStyle)(LocationSearchBar);