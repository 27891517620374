import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";

import "assets/scss/material-kit-react.scss?v=1.4.0";

import HomePage from "views/Client/Home/HomePage";
import SearchPage from "views/Client/Search/SearchPage";

var hist = createBrowserHistory();
const dashboardRoutes = [];

class App extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />

        <Switch>
          <Route path="/home" component={HomePage} />
          <Route path="/search" component={SearchPage} />
          <Route path="/" component={HomePage} />
        </Switch>

        <Footer />
      </div>
    )
  }
}

export default (App);
